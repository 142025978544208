import React from 'react';
import { useState } from 'react';
import people from '../../assets/LogoTeamBodensee450px450px.png';
import urkundeselbstverteidigung from '../../assets/urkundeselbstverteidigung.jpg';
import './header.css';


const Header = () => (
  <div className="protactics__header section__padding" id="home">
    <div className="protactics__header-content">
      <h1 className="gradient__text">Stark gegen Gewalt und Mobbing!</h1>
      <h1 className="gradient__text">Du bist wertvoll!</h1>

      <div className="protactics__header-content__people">
        <img src={people} />
        <p></p>
      </div>
    </div>

    <div className="protactics__header-image">
      <img src={urkundeselbstverteidigung} />
    </div>
  </div>
);

export default Header;
