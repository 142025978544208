import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatprotactics.css';

const Whatprotactics = () => (
  <div className="protactics__whatprotactics section__margin" id="wprotactics">
    <div className="protactics__whatprotactics-feature">
      <Feature title="Was ist M.S.E."/>
    </div>
    <div className="protactics__whatprotactics-heading">
      <h1 className="gradient__text">M.S.E. STEHT FÜR MODERN SELFDEFENSE EDUCATION</h1>
    </div>
    <div className="protactics__whatprotactics-heading">
      <p>
        …und wurde am 01.01.2000 gegründet.
        Es ist das erste und neueste System weltweit im neuen Jahrtausend.
        Dieses System ist in seiner Struktur und seinen individuellen Eigenschaften einzigartig.
        M.S.E. ist ein noch sehr junges System. 
        Die Vereinigung von aggressiver und kontrollierender Selbstverteidigung, umrahmt von psychologischer Beratung, macht dieses System zum Symbol moderner Selbstverteidigung. 
        Es verleiht den Schülern von M.S.E. nicht nur geniale kämpferische Fähigkeiten, sondern formt auch die psychischen Charaktereigenschaften eines jeden lernwilligen Schülers. 
        M.S.E. lehrt zugleich auch, seinen „Feinden“ mit Respekt und Achtung zu begegnen. 
        Der Eigenschutz und der Wille, in Not geratenen Menschen zu helfen, sind die Kernpunkte von M.S.E. 
        Es ist ein reines Selbstverteidigungssystem, das alle Kampfsportarten und Kampfkunstsysteme mit Achtung respektiert. 
        M.S.E. filtert aus den verschiedensten Kampfsportarten die effektivsten Techniken heraus.
        Aggressivität, Mobbing, Demütigungen nehmen ständig zu. Frauen, die sich abends nicht mehr trauen das Haus zu verlassen. Bedrohungen, Messerangriffe usw. überschwemmen die Nachrichten. 
        Der Themenbereich ist viel breiter, als lediglich “Selbstverteidigung”. Deshalb die Namensgebung:
        “PROTACTICS®”. “PRO” bedeutet “wir alle stehen für jemanden, für etwas, für das GUTE”. “TACTICS” steht für die Tatsache, dass wir verschiedene Taktiken einsetzen, um das Gute zu fördern, um zu helfen.
    </p>    
    </div>
    <div className="protactics__whatprotactics-heading">
    <p><a href="https://www.protactics.de/">International Protactics Federation</a></p>
    </div>
    <div className="protactics__whatprotactics-feature">
      <Feature />
    </div>
  </div>
);

export default Whatprotactics;
