import React from 'react';
import Article from '../../components/article/Article';
import haende1 from '../../assets/haende1.png';
import { blog01, blog02, blog03, blog04, blog05 } from './imports';
import './blog.css';

const Blog = () => (
  <section id="features"> 
  <div className="protactics__blog section__padding" id="blog">
    <div className="protactics__blog-heading">
      <h1 className="gradient__text">Was ich anbiete</h1>
    </div>
      <div className="protactics__blog-container">
        <div className="protactics__blog-container_groupA">
          <Article imgUrl={blog01} date="Trainingsort:" text="Bürgerhaus: Hauptstr. 13, 78259 Mühlhausen-Ehingen" text2="" />
      </div>      
      <div className="protactics__blog-container_groupB">           
          <div class="card bg-custom-1">
            <div class="card-header">            
               <img src={haende1} />            
          </div>
          <div class="card-body">
            <h2 class="price-text">Modern Selfdefence Education M.S.E.</h2>
            <p>M. S. E. Modern Selfdefense Education</p> 
            <p>S.A.T. Situational Action Training</p>
            <p>Krav Maga</p>
            <p>Gewaltprävention</p>
            <p>A.A.T. Antiagressionstraining</p>
            <p>Skyboxing</p>
            <p>M.S.E steht für moderne Selbstverteidigung! 
              Selbstverteidigung muss ein natürlicher Reflex sein und in der Realität anwendbar. 
              Durch kontinuierliches Training werden Abläufe und Verteidigungstechniken eingeprägt und sind im Notfall abrufbar. 
              Hier steht der Mensch im Focus, nicht das System! 
              Jeder hat das Recht sich zu wehren: <br/>Du bist wertvoll.</p>                       
          </div>
        </div>
        <div class="card bg-custom-1">
          <div class="card-header">
          <img src={haende1} />  
          </div>
          <div class="card-body">
            <h2 class="price-text">Kickboxen</h2>
            <p>Kickboxen für Kids fördert die Koordination, Kondition, Kraft, Ausdauer, Gleichgewicht & mentale Stärke! Hier vermittel ich einen Einblick in die Welt des Kampfsports.</p>
              <p>Spaß haben & Auspowern.</p>
              <p>Leichtkontakt - keine harten Schläge.</p>              
          </div>
        </div>
        <div class="card bg-custom-2">
          <div class="card-header">
          <img src={haende1} />  
          </div>
          <div class="card-body">
            <h3 class="price-text">5 - 9 Jahre</h3>
            <p>
             Dienstag 16.00 - 17.00 Uhr
            </p>
            <p>
              Moderne Selbsverteidigung M.S.E.
            </p>
            <p>
              Kickboxen
            </p>                        
            <h3 class="price-text">10 - 15 Jahre</h3>            
            <p>              
             Dienstag 17.00 - 18.00 Uhr
            </p>
            <p>              
              Moderne Selbsverteidigung M.S.E.
            </p>
            <p>              
              Kickboxen
            </p>                       
            <h3 class="price-text">Ab 16 Jahre - Frauen</h3>            
            <p>              
             Dienstag 18.00 - 19.30 Uhr
            </p>
            <p>              
              Moderne Selbsverteidigung M.S.E.
            </p>
            <p>              
              Krav Maga
            </p>                       
          </div>
        </div>        
      </div>  
    </div>
  </div> 
</section>
);

export default Blog;
