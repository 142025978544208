import React from 'react';
import possibilityImage from '../../assets/tk1_1.png';
import './possibility.css';

const Possibility = () => (
  <div className="protactics__possibility section__padding" id="possibility">
    <div className="protactics__possibility-image">
      <img src={possibilityImage} alt="Tamara Kopp" />
    </div>
    <div className="protactics__possibility-content">
      <h1 className="gradient__text">Tamara Kopp <br /> Karate Weltmeisterin & Europameisterin</h1>
      <h4>Ich habe viele Jahre Kampfsport betrieben. Im Kampfsport gibt es Gewichtsklassen und Regeln. Auf der Straße  sucht sich der Täter ein Opfer, kein Gegner. Frauen kämpfen anders als Männer. Ich bin keine 1.90 cm groß und wiege keine 100 kg. Ich zeige DIR effektive Techniken und ich weiß,
       wie es sich anfühlt, wenn man sein Leben und das seiner Lieben verteidigt. 
      Täter suchen sich Opfer. Sei kein Opfer oder werde kein Opfer mehr. Ich freue mich dich kennenzulernen. </h4>
      <h2 className="gradient__text">Wenn es dir noch niemand gesagt hat, sage ich es dir: DU BIST WERTVOLL!</h2>
      <p>Instructor für</p>
      <p>M. S. E. Modern Selfdefense Education</p>
      <p>S.A.T. Situational Action</p>
      <p>Krav Maga</p>
      <p>Personal Training</p>
      <p>A.A.T. Antiagression</p>
      <p>Gewaltprävention</p>
      <p>Referee</p>
      <p>Skyboxing</p>
    </div>
  </div>
);

export default Possibility;
