import React from 'react';

import './footer.css';

const Footer = () => (
  <div className="protactics__footer section__padding" id="footer">
    <div className="protactics__footer-heading">
      <h1 className="gradient__text">Nimm Kontakt mit mir auf</h1>
    </div>
    <div className="protactics__footer-btn">
    <a href="mailto:info@protactics-bodensee.de">info@protactics-bodensee.de</a>
    </div>    
    <div className="protactics__footer-btn">
      <p>Mobil + 49 15110679233</p>
    </div>    

    <div className="protactics__footer-links">
      <div className="protactics__footer-links_logo">
        
        <p> <br /> </p>
      </div>
      <div className="protactics__footer-links_div">
        <h2><a href="#impressum">Impressum</a></h2><br/>
        <h2><a href="#datenschutz">Datenschutz</a></h2>
      </div>
      <div className="protactics__footer-links_div">
        <h4></h4>
        <p></p>
        <p></p>
        <p></p>
      </div>
      <div className="protactics__footer-links_div">
        <h4></h4>
        <p></p>
        <p></p>
      </div>
    </div>

    <div className="protactics__footer-copyright">
      <p>@2023 PROTACTICS Bodensee. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
