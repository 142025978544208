import React from 'react';

import './impressum.css';

const Footer = () => (
  




  <div className="protactics__footer">
    <div className="protactics__footer-heading">
      <h1 className="gradient__text">Impressum</h1>
    </div>
    <div className="impressum-subtext">
      <p>Tamara Kopp</p>
        <p>Protactics-Bodensee Selbstverteidigung</p>
        <p>Kirchstr. 6</p>
        <p>78259 Mühlhausen-Ehingen</p>
        <p>Telefon + 49 15110679233</p>
        <p>E-Mail und Internet: info@protactics-bodensee.de</p>
        <p>www.protactics-bodensee.de</p>
        </div>
   </div>
  
);


export default Footer;
